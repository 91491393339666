import React from 'react';
import SocialGroup from './SocialGroup';

const Banner = () => {
  const socials = [
    {
      id: 'web-development-blog',
      text: 'assortment.io',
      href: 'https://assortment.io',
      icon: 'globe'
    },
    {
      id: 'twitter-account',
      text: '@_lukewh',
      href: 'https://twitter.com/_lukewh',
      icon: 'twitter'
    },
    {
      id: 'github-account',
      text: 'lukewhitehouse',
      href: 'https://github.com/lukewhitehouse',
      icon: 'github'
    }
  ];

  return (
    <header className='c-banner' role='banner'>
      <div className='o-container o-container--small'>
        <img className='c-banner__image | u-circular' src='/images/luke-whitehouse.jpg' alt='' />

        <p className='c-banner__intro'>
          Hello, I'm <strong>Luke Whitehouse</strong>, a passionate Web Developer from Leeds with
          over 7 years Industry experience. I'm currently working as a{' '}
          <strong>Senior Developer</strong> for Sky.
        </p>

        <p className='c-banner__text'>
          In the past I’ve had the pleasure of working with clients such as the{' '}
          <strong>North Yorkshire Police</strong>, <strong>The University of Birmingham</strong> and
          the <strong>NHS</strong> to produce modular websites for their users which stand the test
          of time.
        </p>

        <SocialGroup list={socials} />
      </div>
    </header>
  );
};

export default Banner;
