import React from 'react';
import Globe from '../images/globe.svg';
import Twitter from '../images/twitter.svg';
import Github from '../images/github.svg';

const SocialLink = ({ href, text, iconSrc }) => {
  const iconClasses = 'o-icon c-socials__icon';

  const icon = () => {
    switch (iconSrc) {
      case 'github':
        return <Github className={iconClasses} />;
      case 'twitter':
        return <Twitter className={iconClasses} />;
      default:
        return <Globe className={iconClasses} />;
    }
  };

  return (
    <a href={href} className='c-socials__link'>
      {icon()}
      {text}
    </a>
  );
};

export default SocialLink;
