import React from 'react';
import SocialLink from './SocialLink';

const SocialGroup = ({ list }) => {
  const renderSocialLink = (i) => {
    return (
      <li className="c-socials__item" key={i.id}>
        <SocialLink href={i.href} iconSrc={i.icon} text={i.text} />
      </li>
    );
  };

  return (
    <ul className="c-socials | c-banner__socials">
      { list.map((i) => renderSocialLink(i)) }
    </ul>
  );
};

export default SocialGroup;
