import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import '../styles/app.scss';

const PageLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { title }
      }
    }) => (
      <React.Fragment>
        <main>{children}</main>
      </React.Fragment>
    )}
  />
);

export default PageLayout;
