import React from 'react';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';

const IndexPage = () => (
  <PageLayout>
    <MetaTags />
    <Banner />
  </PageLayout>
);

export default IndexPage;
